



































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getFormGroups } from '@/api/leadplus/formgroups'
import CreateFormGroupModal from '@/modules/leadplus/components/CreateFormGroupModal/Index.vue'

@Component({
    name: 'LeadplusGroup',
    components: {
        CreateFormGroupModal,
    },
})
export default class extends Vue {
    @Prop({
        required: true,
        default: () => {
            return ''
        },
    })
    private leadplusGroupId!: any

    private leadplusGroups: any[] = []

    private loading = false

    private noGroupFound = false

    get leadplusGroupIdIsPresent () {
        return this.leadplusGroups.find(el => el.id === this.leadplusGroupId)
    }

    private async getGroups () {
        this.loading = true
        try {
            const response = await getFormGroups({
                limit: 1000,
            })
            this.leadplusGroups = response.data.docs
            this.leadplusGroups.map(e => {
                e['value'] = e['_id']
                e['label'] = e['name']
            })
            this.noGroupFound = this.leadplusGroups.length === 0
        } catch (e) {
            this.leadplusGroups = []
        }
        this.loading = false
    }

    private updateLeadplus (leadplusGroupId: string) {
        this.$emit('update:leadplusGroupId', leadplusGroupId)
    }

    mounted () {
        this.getGroups()
    }
}
