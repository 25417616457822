








































































































































































































































































































import { get } from 'lodash'
import { Component, Mixins } from 'vue-property-decorator'
import { PagesMixins } from './../../../mixins/pages.mixins'
import PageSeoDataInputs from '@/modules/landingpages/components/PageSeoDataInputs/Index.vue'
import PageSocialDataInputs from '@/modules/landingpages/components/PageSocialDataInputs/Index.vue'
import LeadplusGroup from '@/modules/landingpages/components/LeadplusGroup/Index.vue'
import { LandingpageOptionsModule } from '@/store/modules/landingpageoptions'
import VsInlineInputEdit from '@/components/VsInlineInputEdit/index.vue'
import LandingPreview from '@/modules/landingpages/components/LandingPreview/Index.vue'
import PageNameModal from '@/modules/landingpages/components/PageNameModal/Index.vue'
import PageEmailModal from '@/modules/landingpages/components/PageEmailModal/Index.vue'
import VsImage from '@/modules/landingpages/components/VsImage/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSidebarLayout from '@/components/VsSidebarLayout/Index.vue'
import {
    getPages,
    updatePage,
    deletePage,
    duplicatePage,
    changeStatusPage,
    getPageById,
    previewLive,
    preview,
} from '@/api/landingpage/pages'
import { UpdatePageDto } from '@/api/landingpage/dto/pages.dto'
import { PageStatus } from '@/api/landingpage/types/pages.types'
import { exportToZip } from '@/utils/exportToZip'
import { UserModule } from '@/store/modules/user'
import { getSubdomains } from '@/api/landingpage/subdomains'
import { VsToastAspectEnum, VsToastOptionsObjectInterface } from '@advision/vision/src/components/VsToast/types'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'

@Component({
    name: 'LandingpageEdit',
    components: {
        PageSeoDataInputs,
        PageSocialDataInputs,
        LeadplusGroup,
        VsInlineInputEdit,
        LandingPreview,
        PageNameModal,
        PageEmailModal,
        VsImage,
        VsConfirm,
        VsContainer,
        VsSectionHeader,
        VsSeparator,
        VsDropdownButton,
        VsSidebarLayout,
    },
})
export default class extends Mixins(PagesMixins) {
    private showPreview = false
    private previewContent = ''
    private page: any = null
    private loading = false
    private showAlert = false
    private editSubdomainAndSlugModal = false
    private subdomains: any = []
    private urlComplete = ''
    private newSubdomain = ''
    private newSlug = ''

    $refs!: {
        deleteConfirm: VsConfirm
        pageNameModal: PageNameModal
        PageEmailModal: PageEmailModal
    }

    get pageId (): any {
        return get(this.$route, 'params.pageId', '')
    }

    get userRules () {
        return UserModule.user.configuration.rules
    }

    get publicRenderLink () {
        return `${LandingpageOptionsModule.renderUrl}/pages/${this.page._id}/render`
    }

    get canExportHtml () {
        return this.userRules.landingpage.export
    }

    get renderUrl () {
        return new URL(LandingpageOptionsModule.renderUrl)
    }

    get hasShop () {
        return this.userRules.shop
    }

    get subdomainOptions () {
        const subdomain = this.subdomains.map((el: any) => {
            return {
                label: el.name + '.' + this.renderUrl.hostname,
                value: el.name,
            }
        })
        subdomain.push({
            label: `${this.renderUrl.hostname}/pages/${this.page._id}/render`,
            value: null,
        })
        return subdomain
    }

    async beforeMount (): Promise<void> {
        try {
            const res = await getPages({ _id: this.pageId, populate: 'project' })
            if (!res.data.docs || res.data.docs.length === 0) throw new Error('PageNotFound')
            const page = res.data.docs[0]
            if (!page.favicon) page.favicon = null
            if (!page.integrations) {
                page.integrations = {
                    leadplusGroupId: '',
                }
            }
            this.page = page
            this.urlComplete = this.setSubdomainAndSlug()
            this.showAlert = get(this.$route, 'query.pagePublic', false) && page.status === 'enabled'
        } catch (e) {
            console.log(e)
        }
    }

    private setSubdomainAndSlug () {
        this.newSubdomain = this.page.subdomain ? this.page.subdomain : null
        this.newSlug = this.page.slug ? this.page.slug : ''
        if (!this.page.subdomain) {
            return `${this.publicRenderLink}/${this.newSlug}`
        }
        const slug = this.newSlug ? `/${this.newSlug}` : `/${this.page._id}`
        return `${this.renderUrl.protocol}//${this.page.subdomain}.${this.renderUrl.hostname}${slug}`
    }

    private async inlineEdit (e: any, propertyName: string) {
        const data: any = {}
        data[propertyName] = e.value
        await this.save(data)
        e.closeEdit()
    }

    private async savePageName (saveButton?: string) {
        try {
            const pageName = await this.$refs.pageNameModal.open(this.page.name, saveButton)
            try {
                await this.save({ name: pageName })
            } catch (e) {
                return
            }
        } catch (e) {
            console.log(e)
        }
        this.$refs.pageNameModal.close()
    }

    private async saveAllPage () {
        const {
            name,
            description,
            slug,
            subdomain,
            title,
            metaDescription,
            socialTitle,
            socialDescription,
            ogImage,
            favicon,
            twitterImage,
            integrations,
        } = this.page

        this.save({
            name,
            description,
            slug,
            subdomain,
            favicon,
            title,
            metaDescription,
            socialTitle,
            socialDescription,
            ogImage,
            twitterImage,
            integrations,
        })
    }

    async save (data: UpdatePageDto): Promise<void> {
        this.loading = true
        try {
            await updatePage(data, this.pageId)
            this.page = Object.assign(this.page, data)
            this.$root.$vsToast({
                heading: this.$t('landingpages.EditPage.pageEditMessage').toString(),
                timeout: 5000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('landingpages.EditPage.pageEditMessageError').toString(),
                timeout: 5000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async deleteItem (id: string): Promise<void> {
        try {
            await this.$refs.deleteConfirm.openConfirm()
        } catch (e) {
            return
        }
        try {
            await deletePage(id)
            this.$root.$vsToast({
                heading: this.$t('landingpages.pages.pageDeleteMessage').toString(),
                timeout: 5000,
                aspect: VsToastAspectEnum.success,
            })
            this.$router.push({ name: 'PagesCards' })
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: this.$t('landingpages.pages.pageDeleteMessageError').toString(),
                timeout: 5000,
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async duplicatePage (id: string): Promise<void> {
        this.loading = true
        try {
            await duplicatePage(id)
            this.$root.$vsToast({
                heading: this.$t('landingpages.pages.pageDuplicatedMessage').toString(),
                aspect: VsToastAspectEnum.success,
                timeout: 5000,
            })
        } catch (e) {
            const responseMessage = get(e, 'response.data.message', 'default')

            const toastOption: any = {
                heading: this.$t(`landingpages.pages.pageDuplicatedErrors.${responseMessage}`).toString(),
                aspect: VsToastAspectEnum.alert,
                timeout: 5000,
            }
            if (this.hasShop && (responseMessage === 'PagesLimitReached' || responseMessage === 'PagesPublicReached')) {
                toastOption.primaryAction = this.$t(`landingpages.CreatePage.errors.linkAction`).toString()
                toastOption.primaryActionCallback = this.redirectToShopPrices
            }

            this.$root.$vsToast(toastOption)
            this.loading = false
        }
    }

    async changeStatus (value: PageStatus, id: string): Promise<void> {
        try {
            const res = await changeStatusPage(
                { status: value },
                id,
            )

            if (res.data.nModified > 0) {
                const response = await getPageById(id, { fields: 'status,publishedAt' })
                const updatedPage = response.data
                this.page.status = updatedPage.status
                this.page.publishedAt = updatedPage.publishedAt
                if (this.page.status === 'enabled') {
                    this.showAlert = true
                    this.$root.$vsToast({
                        heading: this.$t('landingpages.EditPage.publishSuccess').toString(),
                        primaryAction: this.$t('landingpages.EditPage.showPublish').toString(),
                        primaryActionCallback: () => {
                            window.open(this.urlComplete, '_blank')
                        },
                        aspect: VsToastAspectEnum.success,
                        timeout: 5000,
                    })
                } else {
                    this.$root.$vsToast({
                        heading: this.$t('landingpages.pages.pageStatusMessage').toString(),
                        timeout: 5000,
                    })
                }
            }
        } catch (e) {
            const responseMessage = get(e, 'response.data.message', 'default')
            const toastOption: VsToastOptionsObjectInterface = {
                heading: this.$t(`landingpages.pages.pageStatusErrors.${responseMessage}`).toString(),
                timeout: 5000,
            }
            if (this.hasShop && (responseMessage === 'PagesLimitReached' || responseMessage === 'PagesPublicReached')) {
                toastOption.primaryAction = this.$t(`landingpages.CreatePage.errors.linkAction`).toString()
                toastOption.primaryActionCallback = this.redirectToShopPrices
            }
            this.$root.$vsToast(toastOption)
        }
    }

    async exportToZip (page: any): Promise<void> {
        if (this.canExportHtml) {
            try {
                const res = await previewLive({
                    pageId: page._id,
                    html: page.project.html,
                    css: page.project.css,
                })
                const previewContent = res.data
                exportToZip(previewContent)
            } catch (e) {
                console.log(e)
                this.$root.$vsToast({
                    heading: this.$t(`landingpages.pages.pageExportErrors.default`).toString(),
                    aspect: VsToastAspectEnum.alert,
                    timeout: 5000,
                })
            }
        } else {
            this.$root.$vsOpenBlockedModal()
        }
    }

    private closeAlert () {
        this.showAlert = false
    }

    private redirectToShopPrices () {
        if (this.hasShop) {
            this.$router.push({
                name: 'prices',
            })
        }
    }

    private async runEditorPreview (page: any) {
        this.showPreview = true
        try {
            await this.preview(page._id)
        } catch (error) {
            this.showPreview = false
        }
    }

    private closePreviewDialog (): void {
        this.showPreview = false
        this.previewContent = ''
    }

    private async preview (pageId: string) {
        try {
            const res = await preview(pageId)
            this.previewContent = res.data
        } catch (e) {
            this.previewContent = ''
            console.log(e)
        }
    }

    async sendPagePreview (id: string): Promise<void> {
        try {
            await this.$refs.PageEmailModal.open(id)
        } catch (e) {
            console.log(e)
        }
    }

    private async getSubdomains () {
        this.loading = true
        try {
            const res = await getSubdomains()
            this.subdomains = res.data
        } catch (e) {
            console.log(e)
            this.subdomains = []
        }
        this.loading = false
    }

    private async openEditSlugModal () {
        this.editSubdomainAndSlugModal = true
        await this.getSubdomains()
    }

    private async updateSubdomainAndSlug () {
        const data = {
            slug: this.newSlug,
            subdomain: this.newSubdomain === null ? '' : this.newSubdomain,
        }
        this.loading = true
        try {
            await updatePage(data, this.pageId)
            this.page = Object.assign(this.page, data)
            this.$root.$vsToast({
                heading: this.$t('landingpages.EditPage.pageEditMessage').toString(),
                timeout: 5000,
                aspect: VsToastAspectEnum.success,
            })
            this.urlComplete = this.setSubdomainAndSlug()
            this.editSubdomainAndSlugModal = false
        } catch (e) {
            const err = get(e, 'response.data.message', '')
            if (err && err === 'SlugDuplicate') {
                this.$root.$vsToast({
                    heading: 'Errore durante il salvataggio dei dati, lo slug è già utilizzato su un\'altra landing page associata allo stesso sottodominio',
                    timeout: 5000,
                    aspect: VsToastAspectEnum.alert,
                })
            }
            console.log(e)
        }
        this.loading = false
    }
}
