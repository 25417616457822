






























































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
    name: 'VsInlineInputEdit',
})
export default class extends Vue {
    @Prop({ required: true, default: '' }) private text!: string
    @Prop({ required: false, default: '' }) private editIconText?: string
    @Prop({ required: false, default: '' }) private editIconConfirmText?: string
    @Prop({ required: false, default: '' }) private editIconCancelText?: string
    @Prop({ required: false, default: 'required' }) private rules?: any
    @Prop({ required: false, default: '' }) private name?: string
    private editOn = false
    private message = ''
    private loading = false
    $refs!: {
        vsInlineEditText: any
    }

    private toggleEdit () {
        this.editOn = !this.editOn
    }

    private openEdit () {
        this.loading = false
        this.message = this.text
        this.editOn = true
    }

    private closeEdit () {
        this.editOn = false
    }

    private save () {
        this.loading = true
        this.$emit('save-edit', {
            value: this.message,
            closeEdit: this.closeEdit,
        })
    }

    private cancel () {
        this.$emit('close-edit')
        this.toggleEdit()
    }

    private saveMessage () {
        this.toggleEdit()
    }

    private clearMessage () {
        this.message = ''
    }
}
