








































































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'PageSocialDataInputs',
})
export default class extends Vue {
    @Prop({
        required: true,
        default: '',
    })
    private socialTitle!: string

    @Prop({
        required: true,
        default: '',
    })
    private socialDescription!: string

    @Prop({
        required: true,
        default: '',
    })
    private ogImage!: string

    @Prop({
        required: true,
        default: '',
    })
    private twitterImage!: string

    private addImageToItem (e: any): void {
        const target: string = e.target || ''
        if (!target) return
        this.$emit(`update:${target}`, e.url || '')
    }

    private async openMediaGallery (target: string) {
        try {
            const url = await window.openVsMediaLibrary({
                url: '',
                mode: 'selection',
                allowedTypes: 'image/jpeg,image/gif,image/png,image/x-icon,image/webp,image/ico,image/icon,image/vnd.microsoft.icon',
            })

            this.addImageToItem({
                target,
                url,
            })
        } catch (e) {
            console.log(e)
        }
    }
}
