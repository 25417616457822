import { serviceLandingPage } from '@/utils/request'
import { SubdomainDto } from './dto/subdomains.dto'
import { AxiosResponse } from 'axios'
import { ISubdomain } from './types/subdomain.types'

export const getSubdomains = async (params?: any, cancelToken?: any): Promise<AxiosResponse<ISubdomain>> =>
    serviceLandingPage.get('/subdomains', { params, cancelToken })

export const updateSubdomain = (data: SubdomainDto, id: string, cancelToken?: any): Promise<AxiosResponse<ISubdomain>> =>
    serviceLandingPage.patch(`/subdomains/${id}`, data, { cancelToken })

export const createSubdomain = (data: SubdomainDto, cancelToken?: any): Promise<AxiosResponse<ISubdomain>> =>
    serviceLandingPage.post(`/subdomains`, data, { cancelToken })

export const deleteSubdomain = (id: string, cancelToken?: any) =>
    serviceLandingPage.delete(`/subdomains/${id}`, { cancelToken })
