export const getMainPageReputation = (page: any) => {
    const { name, description, favicon } = page
    if (!name || !description || !favicon) return 'warning'
    return 'success'
}

export const getSeoPageReputation = (page: any) => {
    const { title, metaDescription } = page
    if (!title || !metaDescription) return 'warning'
    return 'success'
}

export const getSocialPageReputation = (page: any) => {
    const { socialTitle, socialDescription, ogImage, twitterImage } = page
    if (!socialTitle || !socialDescription || !ogImage || !twitterImage) return 'warning'
    return 'success'
}

export const getPageReputationColor = (page: any) => {
    if (getMainPageReputation(page) === 'warning' || getSeoPageReputation(page) === 'warning') return 'warning'
    return 'success'
}
