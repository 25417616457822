import Vue from 'vue'
import Component from 'vue-class-component'
import {
    getMainPageReputation,
    getPageReputationColor,
    getSeoPageReputation,
    getSocialPageReputation,
} from './../utils/page.utils'

// You can declare mixins as the same style as components.
@Component
export class PagesMixins extends Vue {
    public getMainPageReputation = getMainPageReputation
    public getPageReputationColor = getPageReputationColor
    public getSeoPageReputation = getSeoPageReputation
    public getSocialPageReputation = getSocialPageReputation
}
